import { Button, Table } from "antd";

import "./OrderPart.css";
import { forwardRef } from "react";

interface OrderPartProps {}

const OrderPart = forwardRef<HTMLDivElement, OrderPartProps>((props, ref) => {
  const dataSource = [
    {
      key: "1",
      requisite: "Отправка",
      company: "ООО КАМАЗ",
      user: "Андрей Андреевич",
      phone_number: "+7 917 123 22 42",
    },
    {
      key: "2",
      requisite: "Доставка",
      company: "ООО ПСГ",
      user: "Иван Иванов",
      phone_number: "+7 917 123 22 42",
    },
  ];

  const columns = [
    {
      title: "Реквизиты доставки",
      dataIndex: "requisite",
      key: "requisite",
    },
    {
      title: "Компания",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "Контактное лицо",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "Мобильный для связи",
      dataIndex: "phone_number",
      key: "phone_number",
    },
  ];
  return (
    <div className="part-main" ref={ref}>
      <div className="part-content-info">
        <span className="circle-block">5</span>
        <span className="part-header-text">Информация доставки</span>
      </div>
      <div className="part-content-main">
        <div className="order-content">
          <Table
            style={{ width: "100%" }}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
          />
        </div>
        <Button
          style={{
            width: "100%",
          }}
          color="danger"
          variant="solid"
        >
          Оформить доставку
        </Button>
      </div>
    </div>
  );
});

export default OrderPart;
