import { Button } from "antd";
import { logOut } from "../../requests/authorization";

import "./Header.css";

interface HeaderProps {
  setIsAuth: (isAuth: boolean) => void;
}

const Header: React.FC<HeaderProps> = ({ setIsAuth }) => {
  return (
    <div className="header-main">
      <div className="header-content">
        <img src="/psg_logo.svg" alt="PSG_LOGO" />
        <Button
          style={{ width: "fit-content" }}
          color="danger"
          variant="solid"
          onClick={() => {
            const res: any = logOut();
            setIsAuth(res);
          }}
        >
          Выйти
        </Button>
      </div>
    </div>
  );
};

export default Header;
