import axios_instance from "./api";

async function checkIsAuthenticated() {
  let result;
  try {
    const response = await axios_instance.get("/is_authenticated");
    result = response.data.status;
  } catch (error) {
    result = false;
  }
  return result;
}

async function logOut() {
  let result;
  await axios_instance
    .get("/logout")
    .then((response) => {
      result = response.data.status;
    })
    .catch((error) => {
      result = false;
    });
  return result;
}

export { checkIsAuthenticated, logOut };
