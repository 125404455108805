import { Button, Table } from "antd";

import "./ResultPart.css";
import { forwardRef, useState } from "react";

interface SelectedResult {
  setSelectedResult: (result: boolean) => void;
}

const ResultPart = forwardRef<HTMLDivElement, SelectedResult>(
  ({ setSelectedResult }, ref) => {
    const [moreResult, setMoreResult] = useState(false);
    const dataSource = moreResult
      ? [
          {
            key: "1",
            tariff: "Быстро",
            delivery_period: "2-3",
            delivery_cost: "124",
          },
          {
            key: "2",
            tariff: "Выгодно",
            delivery_period: "4-7",
            delivery_cost: "12432",
          },
          {
            key: "3",
            tariff: "Экспресс",
            delivery_period: "1-2",
            delivery_cost: "15000",
          },
          {
            key: "4",
            tariff: "Медленно",
            delivery_period: "7-10",
            delivery_cost: "10000",
          },
        ]
      : [
          {
            key: "1",
            tariff: "Быстро",
            delivery_period: "2-3",
            delivery_cost: "124",
          },
          {
            key: "2",
            tariff: "Выгодно",
            delivery_period: "4-7",
            delivery_cost: "12432",
          },
        ];

    const columns = [
      {
        title: "Тарифы",
        dataIndex: "tariff",
        key: "tariff",
      },
      {
        title: "Сроки доставки (дн)",
        dataIndex: "delivery_period",
        key: "delivery_period",
      },
      {
        title: "Стоимость доставки (руб)",
        dataIndex: "delivery_cost",
        key: "delivery_cost",
      },
    ];

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const onSelectChange = (selectedKeys: React.Key[]) => {
      setSelectedRowKeys(selectedKeys);
      setSelectedResult(selectedKeys.length > 0);
    };

    const rowSelection = {
      type: "radio" as "radio",
      selectedRowKeys,
      onChange: onSelectChange,
    };

    return (
      <div className="part-main" ref={ref}>
        <div className="part-content-info">
          <span className="circle-block">4</span>
          <span className="part-header-text">
            Расчеты доставки
            {/* <CalculatorOutlined style={{ fontSize: 50 }} /> */}
          </span>
        </div>
        <div className="part-content-main">
          <div className="result-content">
            <Table
              style={{ width: "100%" }}
              rowSelection={rowSelection}
              dataSource={dataSource}
              columns={columns}
              pagination={false}
            />
            {!moreResult && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="danger"
                  variant="solid"
                  onClick={() => setMoreResult(true)}
                >
                  Загрузить больше
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default ResultPart;
