import { AutoComplete, Button, Empty, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import "./Tracking.css";
import { useState } from "react";

const Tracking = () => {
  const [selectedTracking, setSelectedTracking] = useState({
    id: null,
    date: null,
    source: null,
    target: null,
    status: null,
  });
  type TrackingType = keyof typeof selectedTracking;

  const dataSource = [
    {
      key: "1",
      id: "235264642363462",
      date: "02.01.2030",
      source: "Москва",
      target: "ул. Вахитов д. 20",
      status: "Принят в пути",
    },
    {
      key: "2",
      id: "6346235264642363462",
      date: "02.01.2030",
      source: "Красноярск",
      target: "ул. Пушкина д. 30",
      status: "Отправлено",
    },
    {
      key: "3",
      id: "87078235264642363462",
      date: "02.01.2030",
      source: "Санкт Петербург",
      target: "ул. Мира д. 27",
      status: "Вручено",
    },
  ];

  const columns = [
    {
      title: "Срок доставки",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Город",
      dataIndex: "source",
      key: "source",
    },
    {
      title: "Адрес доставки",
      dataIndex: "target",
      key: "target",
    },
  ];

  const handleTrackingChange = (value: any) => {
    const track: any = dataSource.find((track) => track.id === value);
    setSelectedTracking(track);
  };

  return (
    <div className="part-main">
      {!selectedTracking.date ? (
        <Empty
          style={{ width: "100%" }}
          imageStyle={{ height: 50 }}
          image={
            <SearchOutlined style={{ fontSize: 50, color: "#00000020" }} />
          }
          description={
            <span className="emty-description-text">
              Введите идентификатор доставки
            </span>
          }
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 10,
              width: "100%",
            }}
          >
            <Select
              style={{ width: "100%" }}
              showSearch
              placeholder="Поиск по идентификатору"
              optionFilterProp="label"
              options={dataSource.map((cell) => ({
                value: cell.id,
                label: cell.id,
              }))}
              onChange={handleTrackingChange}
              notFoundContent="Не найдено такой доставки"
            />
          </div>
        </Empty>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
            width: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 10,
              }}
            >
              <span className="part-header-text">Статус:</span>
              <span>{selectedTracking.status}</span>
            </div>
            <Button
              color="danger"
              variant="solid"
              onClick={() => {
                setSelectedTracking({
                  id: null,
                  date: null,
                  source: null,
                  target: null,
                  status: null,
                });
              }}
            >
              Проверить другую доставку
            </Button>
          </div>
          <div className="part-content-cell">
            {columns.map((cell) => {
              const x: TrackingType = cell.key as TrackingType;
              return (
                <div className="cell">
                  <span style={{ opacity: "50%" }}>{cell.title}</span>
                  <AutoComplete
                    disabled
                    value={selectedTracking[x]}
                    placeholder={cell.title}
                    style={{ width: "100%" }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Tracking;
