import Step1 from "./step1/Step1";

import "./Auth.css";

interface AuthProps {
  setIsAuth: (isAuth: boolean) => void;
}

const Auth: React.FC<AuthProps> = ({ setIsAuth }) => {
  return (
    <div className="auth-page-main">
      <div className="auth-page-content">
        <Step1 setIsAuth={setIsAuth} />
      </div>
    </div>
  );
};

export default Auth;
