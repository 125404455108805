import ProductPart from "./productPart/ProductPart";
import DirectionPart from "./directionPart/DirectionPart";

import "./CalcContainer.css";
import ResultPart from "./resultPart/ResultPart";
import { Tabs } from "antd";
import { useEffect, useRef, useState } from "react";
import SenderPart from "./senderPart/SenderPart";
import OrderPart from "./orderPart/OrderPart";
import Tracking from "./tracking/Tracking";

const CalcContainer = () => {
  const resultRef = useRef<HTMLDivElement>(null);
  const orderRef = useRef<HTMLDivElement>(null);
  const [result, setResult] = useState(false);
  const [selectedResult, setSelectedResult] = useState(false);
  const tabs = [
    {
      label: <span className="tab-keys-text">Рассчитать доставку</span>,
      key: "1",
      children: (
        <div className="calc-container-content">
          <SenderPart />
          <DirectionPart />
          <ProductPart setResult={setResult} />
          {result && (
            <ResultPart setSelectedResult={setSelectedResult} ref={resultRef} />
          )}
          {selectedResult && <OrderPart ref={orderRef} />}
        </div>
      ),
    },
    {
      label: <span className="tab-keys-text">Проверить трекинг по номеру</span>,
      key: "2",
      children: <Tracking />,
    },
  ];

  useEffect(() => {
    if (result && resultRef.current) {
      resultRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [result]);

  useEffect(() => {
    if (selectedResult && orderRef.current) {
      orderRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedResult]);

  return (
    <div className="calc-container-main">
      <Tabs defaultActiveKey="1" items={tabs} style={{ width: "100%" }} />
    </div>
  );
};

export default CalcContainer;
