import { AutoComplete, Button, Empty, Select } from "antd";
import { BankOutlined } from "@ant-design/icons";

import "./DirectionPart.css";
import { useState } from "react";

const DirectionPart = () => {
  const [warehouse, setWarehouse] = useState({
    id: "",
    name: "",
    city: "",
    address: "",
  });
  const [newWarehouse, setNewWarehouse] = useState(false);

  type WarehouseType = keyof typeof warehouse;

  const warehouses = [
    {
      id: "1",
      name: "Камаз",
      city: "Москва",
      address: "Ул. Мира",
    },
    {
      id: "2",
      name: "Kamaz",
      city: "Казань",
      address: "ул. Вахитова",
    },
    {
      id: "3",
      name: "Saie",
      city: "Самара",
      address: "ул. Центральная",
    },
  ];

  const columns = [
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Город",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Точный адрес",
      dataIndex: "address",
      key: "address",
    },
  ];

  const handleSenderChange = (type: WarehouseType, value: any) => {
    const warehouse: any = warehouses.find(
      (warehouse) => warehouse[type] === value
    );
    setWarehouse(warehouse);
  };

  const handleInputChange = (field: string, value: any) => {
    setWarehouse((prevSender) => ({
      ...prevSender,
      [field]: value,
    }));
  };

  return (
    <div className="part-main">
      <div className="part-content-info">
        <span className="circle-block">2</span>
        <span className="part-header-text">Маршрут доставки</span>
      </div>
      <div className="part-content-main">
        {!warehouse.id && !newWarehouse ? (
          <Empty
            style={{ width: "100%" }}
            image={
              <BankOutlined style={{ fontSize: 50, color: "#00000040" }} />
            }
            imageStyle={{ height: 60 }}
            description={
              <span className="emty-description-text">
                Выберите склад компании или создайте новый
              </span>
            }
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
              <Select
                optionFilterProp="label"
                onSearch={(value: string) => handleInputChange("id", value)}
                onChange={(value: string) => handleSenderChange("id", value)}
                showSearch
                placeholder="Поиск по ИНН/Наименованию/Адресу/Email"
                style={{ width: "100%" }}
                options={warehouses.map((cell) => ({
                  value: cell.id,
                  label: cell.name,
                }))}
                notFoundContent="Нет совпадений"
              />
              <Button
                color="danger"
                variant="solid"
                onClick={() => setNewWarehouse(true)}
                style={{ width: "100%" }}
              >
                Добавить склад
              </Button>
            </div>
          </Empty>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              width: "100%",
            }}
          >
            <div
              style={{ width: "100%", display: "flex", justifyContent: "end" }}
            >
              <Button
                color="danger"
                variant="solid"
                onClick={() => {
                  setNewWarehouse(false);
                  setWarehouse({
                    id: "",
                    name: "",
                    city: "",
                    address: "",
                  });
                }}
              >
                Вернуться
              </Button>
            </div>
            <div className="part-content-cell">
              {columns.map((cell) => {
                const x: WarehouseType = cell.key as WarehouseType;
                return (
                  <div className="cell">
                    <span style={{ opacity: "50%" }}>{cell.title}</span>
                    <AutoComplete
                      disabled={!newWarehouse}
                      value={warehouse[x]}
                      onSearch={(value: string) =>
                        handleInputChange(cell.key, value)
                      }
                      onSelect={(value: string) => handleSenderChange(x, value)}
                      placeholder={cell.title}
                      style={{ width: "100%" }}
                    >
                      {warehouses
                        .filter((option) => option[x].includes(warehouse[x]))
                        .map((option) => (
                          <AutoComplete.Option
                            key={option[x]}
                            value={option[x]}
                          >
                            {option[x]}
                          </AutoComplete.Option>
                        ))}
                    </AutoComplete>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DirectionPart;
