import { AutoComplete, Button, Empty, Select } from "antd";

import "./SenderPart.css";
import { useState } from "react";

const SenderPart = () => {
  const [newSender, setNewSender] = useState(false);
  const [sender, setSender] = useState({
    name: "",
    inn: "",
    entity: "",
    email: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    phone: "",
    self_email: "",
  });
  type SenderType = keyof typeof sender;

  const handleSenderChange = (type: SenderType, value: any) => {
    const sender: any = senders.find((sender) => sender[type] === value);
    setSender(sender);
  };

  const handleInputChange = (field: string, value: any) => {
    setSender((prevSender) => ({
      ...prevSender,
      [field]: value,
    }));
  };

  const senders = [
    {
      contact: "ООО 1650686816gdfg506868@mail.ru",
      name: "6868165dfg06868",
      inn: "124124124",
      entity: "Камаз",
      email: "mail@mail.ru",
      first_name: "John",
      middle_name: "dsgsdohn",
      last_name: "Dohn",
      phone: "124634634",
      self_email: "gsdgkj@mail.ru",
    },
    {
      contact: "ООО 1650686816506868@mail.ru",
      name: "686816506868",
      inn: "124124124325532",
      entity: "Master",
      email: "mвапail@mail.ru",
      first_name: "John",
      middle_name: "dsgsdohn",
      last_name: "Dohn",
      phone: "124634634",
      self_email: "gsdgkj@mail.ru",
    },
    {
      contact: "ООО 1650686816506868@mail.ru",
      name: "686816506868",
      inn: "124124123252354",
      entity: "Super",
      email: "maцуеil@mail.ru",
      first_name: "John",
      middle_name: "dsgsdohn",
      last_name: "Dohn",
      phone: "124634634",
      self_email: "gsdgkj@mail.ru",
    },
  ];

  const columns = [
    {
      title: "Наименование компаний",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "ИНН",
      dataIndex: "inn",
      key: "inn",
    },
    {
      title: "Почта компаний",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Имя",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Отчество",
      dataIndex: "middle_name",
      key: "middle_name",
    },
    {
      title: "Фамилия",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Ваша почта",
      dataIndex: "self_email",
      key: "self_email",
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "phone",
    },
  ];

  return (
    <div className="part-main">
      <div className="part-content-info">
        <span className="circle-block">1</span>
        <span className="part-header-text">
          Информация о покупателе
          {/* <SolutionOutlined style={{ fontSize: 50 }} /> */}
        </span>
      </div>
      <div className="part-content-main">
        {!sender.inn && !newSender ? (
          <Empty
            style={{ width: "100%" }}
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{ height: 60 }}
            description={
              <span className="emty-description-text">
                Выберите свою компанию или создайте новую
              </span>
            }
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
              <Select
                optionFilterProp="label"
                onSearch={(value: string) => handleInputChange("entity", value)}
                onChange={(value: string) =>
                  handleSenderChange("entity", value)
                }
                showSearch
                placeholder="Поиск по ИНН/Наименованию/Адресу/Email"
                style={{ width: "100%" }}
                options={senders.map((sender) => ({
                  value: sender.entity,
                  label: sender.entity,
                }))}
                notFoundContent="Нет совпадений"
              />
              <Button
                color="danger"
                variant="solid"
                onClick={() => setNewSender(true)}
                style={{ width: "100%" }}
              >
                Добавить покупателя
              </Button>
            </div>
          </Empty>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              width: "100%",
            }}
          >
            <div
              style={{ width: "100%", display: "flex", justifyContent: "end" }}
            >
              <Button
                color="danger"
                variant="solid"
                onClick={() => {
                  setNewSender(false);
                  setSender({
                    name: "",
                    inn: "",
                    entity: "",
                    email: "",
                    first_name: "",
                    middle_name: "",
                    last_name: "",
                    phone: "",
                    self_email: "",
                  });
                }}
              >
                Вернуться
              </Button>
            </div>
            <div className="part-content-cell">
              {columns.map((cell) => {
                const x: SenderType = cell.key as SenderType;
                return (
                  <div className="cell">
                    <span style={{ opacity: "50%" }}>{cell.title}</span>
                    <AutoComplete
                      disabled={!newSender}
                      value={sender[x]}
                      onSearch={(value: string) =>
                        handleInputChange(cell.key, value)
                      }
                      onSelect={(value: string) => handleSenderChange(x, value)}
                      placeholder={cell.title}
                      style={{ width: "100%" }}
                    >
                      {senders
                        .filter((option) => option[x].includes(sender[x]))
                        .map((option) => (
                          <AutoComplete.Option
                            key={option[x]}
                            value={option[x]}
                          >
                            {option[x]}
                          </AutoComplete.Option>
                        ))}
                    </AutoComplete>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SenderPart;
