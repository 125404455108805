import { useEffect, useState } from "react";
import Header from "../../layout/header/Header";
import CalcContainer from "./calcContainer/CalcContainer";

import "./MainPage.css";
import Auth from "./auth/Auth";
import { checkIsAuthenticated } from "../../requests/authorization";
import axios_instance from "../../requests/api";

const MainPage = () => {
  const [isAuth, setIsAuth] = useState(false);

  const getMe = async () => {
    await axios_instance
      .get("/getMe")
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    const authStatus: any = checkIsAuthenticated();
    setIsAuth(authStatus);
    getMe();
  }, []);

  return (
    <div className="main-page-main">
      <Header setIsAuth={setIsAuth} />
      <div className="main-page-content">
        {isAuth ? <CalcContainer /> : <Auth setIsAuth={setIsAuth} />}
      </div>
    </div>
  );
};

export default MainPage;
