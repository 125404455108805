import { AutoComplete, Button, Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import "./Step1.css";
import { useState } from "react";
import type { AutoCompleteProps } from "antd";
import axios_instance from "../../../../requests/api";

interface Step1Props {
  setIsAuth: (isChecked: boolean) => void;
}

const Step1: React.FC<Step1Props> = ({ setIsAuth }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [btnPressed, setBtnPressed] = useState(false);
  const [options, setOptions] = useState<AutoCompleteProps["options"]>([]);

  const handleSearch = (value: string) => {
    setOptions(() => {
      if (!value || value.includes("@")) {
        return [];
      }
      return ["mail.ru", "gmail.com", "yandex.ru"].map((domain) => ({
        label: `${value}@${domain}`,
        value: `${value}@${domain}`,
      }));
    });
  };

  async function performAuthentication() {
    await axios_instance
      .post("/login", {
        email: email,
        password: password,
      })
      .then((response) => {
        setIsAuth(response.data.status);
        setError(!response.data.status);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <img
        src="/psg_logo.svg"
        alt="PSG_LOGO"
        style={{ height: 50, margin: "30px 0" }}
      />

      <AutoComplete
        style={{ width: "80%", height: 40 }}
        status={btnPressed && email === "" ? "error" : ""}
        onSearch={handleSearch}
        placeholder="Введите email"
        options={options}
        value={email}
        onChange={(value: string) => setEmail(value)}
      />
      <Input.Password
        style={{ width: "80%", height: 40 }}
        status={(btnPressed && !password && !email) || error ? "error" : ""}
        placeholder="Введите пароль"
        iconRender={(visible) =>
          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
        }
        value={password}
        onChange={(e: any) => setPassword(e.target.value)}
      />
      <div style={{ width: "80%", display: "flex", justifyContent: "end" }}>
        <Button
          style={{ width: "fit-content" }}
          size="large"
          color="danger"
          variant="solid"
          onClick={() => {
            setBtnPressed(true);
            if (email && password) {
              performAuthentication();
            }
          }}
        >
          Далее
        </Button>
      </div>
    </>
  );
};

export default Step1;
