import { Button, Select, AutoComplete, Empty } from "antd";
import { InboxOutlined } from "@ant-design/icons";

import "./ProductPart.css";
import { useState } from "react";

interface Result {
  setResult: (result: boolean) => void;
}

const ProductPart: React.FC<Result> = ({ setResult }) => {
  const [newProduct, setNewProduct] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({
    article: "",
    name: "",
    brand: "",
    manufacture: "",
    length: "",
    height: "",
    width: "",
    weight: "",
    volume: "",
    count: "",
    price: "",
    cost: "",
  });

  type ProductType = keyof typeof selectedProduct;

  const products = [
    {
      article: "W12345",
      name: "Колесо",
      brand: "Камаз",
      manufacture: "Производитель 1",
      length: "20",
      height: "20",
      width: "23",
      weight: "10",
      volume: "0.04",
      count: "50",
      price: "2000",
      cost: "100000",
    },
    {
      article: "S67890",
      name: "Руль",
      brand: "Мерседес",
      manufacture: "Производитель 2",
      length: "15",
      height: "15",
      width: "23",
      weight: "5",
      volume: "0.02",
      count: "30",
      price: "3000",
      cost: "90000",
    },
    {
      article: "P11121",
      name: "Педаль",
      brand: "Лада",
      manufacture: "Производитель 3",
      length: "10",
      height: "5",
      width: "23",
      weight: "1",
      volume: "0.005",
      count: "100",
      price: "500",
      cost: "50000",
    },
  ];

  const columns = [
    {
      title: "Артикул",
      key: "article",
      dataIndex: "article",
    },
    {
      title: "Наименование",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Бренд",
      key: "brand",
      dataIndex: "brand",
    },
    {
      title: "Производитель",
      key: "manufacture",
      dataIndex: "manufacture",
    },
    {
      title: "Длина, см",
      key: "length",
      dataIndex: "length",
    },
    {
      title: "Высота, см",
      key: "height",
      dataIndex: "height",
    },
    {
      title: "Ширина, см",
      key: "width",
      dataIndex: "width",
    },
    {
      title: "Вес, кг",
      key: "weight",
      dataIndex: "weight",
    },
    {
      title: "Объем, см3",
      key: "volume",
      dataIndex: "volume",
    },
    {
      title: "Количество",
      key: "count",
      dataIndex: "count",
    },
    {
      title: "Цена, руб",
      key: "price",
      dataIndex: "price",
    },
    {
      title: "Стоимость, руб",
      key: "cost",
      dataIndex: "cost",
    },
    {
      title: "",
      key: "btn",
      dataIndex: "btn",
    },
  ];

  const handleProductChange = (value: any) => {
    const product: any = products.find((product) => product.article === value);
    setSelectedProduct(product);
  };

  const handleInputChange = (field: string, value: any) => {
    setSelectedProduct((prevProduct) => ({
      ...prevProduct,
      [field]: value,
    }));
  };

  return (
    <div className="part-main">
      <div className="part-content-info">
        <span className="circle-block">3</span>
        <span className="part-header-text">
          Иноформация о товаре
          {/* <InboxOutlined style={{ fontSize: 50 }} /> */}
        </span>
      </div>
      <div className="part-content-main">
        {!selectedProduct.brand && !newProduct ? (
          <Empty
            style={{ width: "100%" }}
            imageStyle={{ height: 50 }}
            // image={
            //   <InboxOutlined style={{ fontSize: 50, color: "#00000020" }} />
            // }
            description={
              <span className="emty-description-text">
                Выберите существующий или добавьте новый товар в нашу базу
                данных
              </span>
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                width: "100%",
              }}
            >
              <Select
                style={{ width: "100%" }}
                showSearch
                placeholder="Поиск по артикулу/наименованию/бренду/производителю"
                optionFilterProp="label"
                options={products.map((product) => ({
                  value: product.article,
                  label: product.article,
                }))}
                onChange={handleProductChange}
                notFoundContent="Нет совпадений"
              />
              <Button
                style={{ width: "100%" }}
                color="danger"
                variant="solid"
                onClick={() => setNewProduct(true)}
              >
                Новый товар
              </Button>
            </div>
          </Empty>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              width: "100%",
            }}
          >
            <div
              style={{ width: "100%", display: "flex", justifyContent: "end" }}
            >
              <Button
                color="danger"
                variant="solid"
                onClick={() => {
                  setNewProduct(false);
                  setSelectedProduct({
                    article: "",
                    name: "",
                    brand: "",
                    manufacture: "",
                    length: "",
                    height: "",
                    width: "",
                    weight: "",
                    volume: "",
                    count: "",
                    price: "",
                    cost: "",
                  });
                }}
              >
                Вернуться
              </Button>
            </div>
            <div className="part-content-cell">
              {columns.map((cell) => {
                const x: ProductType = cell.key as ProductType;
                return (
                  <div className="cell">
                    {cell.key !== "btn" ? (
                      <>
                        <span style={{ opacity: "50%" }}>{cell.title}</span>
                        <AutoComplete
                          disabled={!newProduct}
                          value={selectedProduct[x]}
                          onSearch={(value: string) =>
                            handleInputChange(cell.key, value)
                          }
                          onSelect={(value: string) => {
                            if (x === "article") {
                              handleProductChange(value);
                            } else {
                              handleInputChange(cell.key, value);
                            }
                          }}
                          placeholder={cell.title}
                          style={{ width: "100%" }}
                        >
                          {products
                            .filter((option) =>
                              option[x].includes(selectedProduct[x])
                            )
                            .map((option) => (
                              <AutoComplete.Option
                                key={option[x]}
                                value={option[x]}
                              >
                                {option[x]}
                              </AutoComplete.Option>
                            ))}
                        </AutoComplete>{" "}
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          height: "100%",
                          alignItems: "end",
                        }}
                      >
                        <Button
                          style={{
                            width: "100%",
                          }}
                          color="danger"
                          variant="solid"
                          onClick={() => setResult(true)}
                        >
                          Рассчитать
                        </Button>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductPart;
